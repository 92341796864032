import ConstructionCase from '../construction_case';

const ImageFavorite = {
  init: function() {
    this.searchImages()
    this.handleSearchImagesCountClick()
    this.handleDeleteImageFavorite()
  },
  searchImages: function () {
    $('#submitMyPageSearchImage').click(function () {
      const selectedTags = $('#searchCondTag').find('input[type="checkbox"]:checked').map(function () { return $(this).val() }).get();
      let searchUrl = '/mypage/search_images?'

      let queryParams = ''

      if (selectedTags.length) {
        selectedTags.forEach(function (tag) {
          queryParams = `${queryParams}tags[]=${tag}&`
        })
      }

      const nextURL = `${window.location.origin}/mypage/images?${queryParams}`
      const nextTitle = document.title
      const nextState = {search: 'update search result'}
      window.history.replaceState(nextState, nextTitle, nextURL);

      $('.mypageImagesLoadingImage').show()
      $('.mypage-image-container').hide()

      $.ajax({
        url: searchUrl,
        method: 'GET',
        data: {
          'tags[]': selectedTags,
        },
        success: function (res) {
          setTimeout(function () {
            $('.mypageImagesLoadingImage').hide()
            $('.mypage-image-container').show()

            // MEMO: ajax のページ遷移後にスクロールしないと画像が表示されない問題の対応
            $('.menu.nomb').css('opacity','1')
            $('.ul10.mypage-image-container').css('opacity','1')
            $('.menu.nomb').css('transform','translateY(0)')
            $('.ul10.mypage-image-container').css('transform','translateY(0)')

            ImageFavorite.init()
            ConstructionCase.initSwiper()
            ConstructionCase.initModal()
          }, 50)
          window.lazyLoadInstance.update()
        },
        error: function (request, status, error) {
          return toastr.error('エラーが発生しました。')
        }
      })
    })
  },
  handleSearchImagesCountClick: function () {
    $('.my-page-search-image-filter').click(function () {
      ImageFavorite.searchImagesCount()
    })
  },
  searchImagesCount: function () {
    const selectedTags = $('#searchCondTag').find('input[type="checkbox"]:checked').map(function() { return $(this).val() }).get();
    let searchUrl = '/mypage/search_images_count?'

    $.ajax({
      url: searchUrl,
      method: 'GET',
      data: {
        'tags[]': selectedTags,
      },
      error: function (request, status, error) {
        return toastr.error('エラーが発生しました。')
      }
    })
  },

  handleDeleteImageFavorite: function() {
    $('.deleteImageFavorite').click(function (e) {
      e.preventDefault()

      let construction_case_image_id = $(this).attr('data-image-id')
      $.ajax({
        url: '/mypage/destroy_construction_case_image_favorite',
        type: 'DELETE',
        dataType: 'json',
        data: { construction_case_image_id: construction_case_image_id },
        success: function(data) {
          if (data.status === 200) {
            toastr.success('削除に成功しました。')
            $('.favorite-image[data-image-id="' + construction_case_image_id + '"]').remove();
            $('.total.favorite-image-count span').text($('.favorite-image').length)
          }
        },
        error: function(XMLHttpRequest, textStatus, errorThrown) {
          toastr.error('削除に失敗しました。')
        }
      });
    })
  }
}

export default ImageFavorite;
