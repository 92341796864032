import { ValidationForm } from '../../src/web/validation_form'

const BUKKEN_KEY = 'bukken_contact'

var Sales = {
  init() {
    if ($('.Sales.confirm').length) {
      Sales.removeContactFields();
    }
    if (!$('.Sales.sales-detail').length) {
      return
    }

    Sales.toggleDateRequire();
    Sales.initDayPicker();
    Sales.saveAddress();
    Sales.initUIInteraction();
    Sales.validateFormContact();
    Sales.fillContactFields(BUKKEN_KEY);
    window.onload = () => {
      Sales.initSlides();
      $('.carousel__loading').hide();
    };
  },

  initSlides: function () {
    $('.carousel .slider__main').on('init', function(event, slick) {
      let img = $(slick.$slides.get(0)).find('img.slider__img');
      $('.slider__alt').text(img.attr('alt') || '');
      $('.slider__comment').text(img.data('comment') || '');
      $('.slider__current').text(slick.currentSlide + 1);
      $('.slider__total').text(slick.slideCount);
    })

    .slick ({
      speed: 1000,
      arrows: true,
      asNavFor: ".carousel .slider__nav",
    })

    .on('afterChange', function(event, slick, currentSlide) {
      let img = $(slick.$slides.get(currentSlide)).find('img.slider__img');
      $('.slider__alt').text(img.attr('alt') || '');
      $('.slider__comment').text(img.data('comment') || '');
      $('.slider__current').text(currentSlide + 1);
    });

    $('.carousel .slider__nav').slick({
      slidesToShow: 6,
      focusOnSelect: true,
      asNavFor: ".carousel .slider__main",
    });
  },

  toggleDateRequire: function () {
    $('input.Form__checkbox_content').on('change', function() {
      if (Sales.isDateRequire()) {
        $('span.date-required').addClass('active');
      } else {
        $('span.date-required').removeClass('active');
      }
    })
  },

  initDayPicker: function () {
    const today = $.datepicker.formatDate( "yy-M-dd", new Date());

    $("#datepicker").datepicker({
      minDate: 0,
      prevText: "前",
      nextText: "次",
      monthNames: [ "1月", "2月", "3月", "4月", "5月", "6月",
        "7月", "8月", "9月", "10月", "11月", "12月" ],
      monthNamesShort: [ "1月", "2月", "3月", "4月", "5月", "6月",
        "7月", "8月", "9月", "10月", "11月", "12月" ],
      dayNames: [ "日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日" ],
      dayNamesShort: [ "日", "月", "火", "水", "木", "金", "土" ],
      dayNamesMin: [ "日", "月", "火", "水", "木", "金", "土" ],
      weekHeader: "週",
      showWeek: false,
      dateFormat: "yy年mm月dd日",
      firstDay: 0,
      isRTL: false,
      showMonthAfterYear: true,
      yearSuffix: "年",
      showButtonPanel: true,
      closeText: "閉じる",
      currentText: "今日",
      beforeShowDay: function(date) {
        const f_date = $.datepicker.formatDate( "yy-M-dd", date);
        if (today === f_date) return [false, '']; // 今日は選択不可
        if (date.getDay() === 3) return [false, ''];  // 定休日（水曜日）

        return [true, ''];
      }
    });
  },

  initUIInteraction: function () {
    $('.partition_info__head').on('click', function() {
      $(this).children('.partition_info__title').toggleClass('js_open');
      $(this).parent().children('.partition_info__contents').animate({
        height: 'toggle'
      });
    });

    $('.partition_image_list__head').on('click', function() {
      $(this).children('.partition_image_list__title').toggleClass('js_open');
      $(this).parent().children('.partition_image_list__list').animate({
        height: 'toggle'
      });
    });

    $('.Property__accordionBtn').on('click', function () {
      $(this).addClass('active');
      $(this).parent().children('.Property__accordionCollapse').addClass('active')
    });
  },


  saveAddress () {
    const ValidateForm = new ValidationForm();
    ValidateForm.saveAddress($('.temporary-address'), $('#Form__input_address'));
  },

  validateFormContact: function () {
    $('#form-confirm').on('click', function(e) {
      e.preventDefault();
      $('.errors-messages').html(null)

      const ValidateForm = new ValidationForm();
      const isInputValid = ValidateForm.handleValidateInputForm($('.required'));
      const isSelectValid = ValidateForm.handleValidateInputSelectForm($('.required-select'))
      const isValidFurigana = ValidateForm.handleValidateFurigana();
      const isValidZipcode = ValidateForm.handleValidateZipcode();
      const isValidPhone = Sales.handleValidatePhone();
      const isValidEmail = ValidateForm.handleValidateEmail();
      const isValidAddress = ValidateForm.validateAddress($('#Form__input_address'), $('.temporary-address'));
      const isValidContentInquiry = ValidateForm.handleValidateCheckboxForm(
        $('.content-inquiry').find('input:checkbox')
      );
      const isValidCustomerFrom = ValidateForm.handleValidateCheckboxForm(
        $('.customer-from').find('input:checkbox')
      );
      let isDateValid = true;
      let isTimeValid = true;
      if (Sales.isDateRequire()) {
        isDateValid = ValidateForm.handleValidateInputForm($('.date-required__required'));
        isTimeValid = ValidateForm.handleValidateInputSelectForm($('.date-required__required-select'))
      }
      const acceptPolicy = ValidateForm.handleValidateAcceptPolicy()

      const isValid = isInputValid && isValidFurigana && isValidZipcode && isSelectValid &&
                      isValidContentInquiry && isValidCustomerFrom && isDateValid &&
                      isTimeValid && isValidPhone && isValidEmail && isValidAddress && acceptPolicy

      if (isValid) {
        Sales.setAttributesContact(BUKKEN_KEY);
        $('#bukken-contact').submit();
      } else {
        $('html, body').animate({
          scrollTop: $('.Form').offset().top
        }, 500);
      }
    });
  },

  setAttributesContact: function(contactKey) {
    let contact = {
      bukken_contact: {
        content_type: $('.Form__checkbox_content:checked').get().map( e => e.value),
        content_type_other: $('#Form__input_content_type_other').val(),
        visit_date: $('.Form__input_date').val(),
        visit_time: $('#Form__select_time').val(),
        name: $('#Form__input_name').val(),
        name_kana: $('#Form__input_name_kana').val(),
        zipcode: $('#Form__input_zipcode').val(),
        prefecture: $('#Form__select_prefecture').val(),
        city: $('#Form__input_city').val(),
        address: $('#Form__input_address').val(),
        phone: $('#Form__input_phone').val(),
        phone_contact_method: $('.Form__radio_phone_contact_method:checked').val(),
        email: $('#Form__input_email').val(),
        customer_from: $('.Form__checkbox_customer_from:checked').get().map( e => e.value),
        customer_from_magazine_name: $('#Form__input_customer_from_magazine_name').val(),
        customer_from_staff_name: $('#Form__input_customer_from_staff_name').val(),
        customer_from_introducer_name: $('#Form__input_customer_from_introducer_name').val(),
        customer_from_other: $('#Form__input_customer_from_other').val(),
        contact_way: $('.Form__radio_contact_way:checked').val(),
        mailcheck: $('.Form__radio_mailcheck:checked').val(),
        accept_privacy: $('#Form__checkbox_privacy').is(':checked')
      }
    }

    localStorage.setItem(contactKey, JSON.stringify(contact))
  },

  fillContactFields: function (contactKey) {
    let contactFieldsJson = localStorage.getItem(contactKey)
    let contactFields = JSON.parse(contactFieldsJson)

    if (contactFields) {
      contactFields = contactFields.bukken_contact

      contactFields.content_type.forEach(function (i) {
        $(`.Form__checkbox_content:checkbox[value=${i}]`).prop('checked', true);
      });

      if ($('#Form__input_content_type_other').val()?.length === 0) {
        $('#Form__input_content_type_other').val(contactFields.content_type_other)
      }

      if ($('.Form__input_date').val()?.length === 0) {
        $('.Form__input_date').val(contactFields.visit_date)
      }

      $('#Form__select_time').val(contactFields.visit_time);

      if ($('#Form__input_name').val()?.length === 0) {
        $('#Form__input_name').val(contactFields.name)
      }

      if ($('#Form__input_name_kana').val()?.length === 0) {
        $('#Form__input_name_kana').val(contactFields.name_kana)
      }

      if ($('#Form__input_zipcode').val()?.length === 0) {
        $('#Form__input_zipcode').val(contactFields.zipcode)
      }

      $('#Form__select_prefecture').val(contactFields.prefecture);

      if ($('#Form__input_city').val()?.length === 0) {
        $('#Form__input_city').val(contactFields.city)
      }

      if ($('#Form__input_address').val()?.length === 0) {
        $('#Form__input_address').val(contactFields.address)
      }

      if ($('#Form__input_phone').val()?.length === 0) {
        $('#Form__input_phone').val(contactFields.phone)
      }

      $(`.Form__radio_phone_contact_method:radio[value='${contactFields.phone_contact_method}']`).prop('checked', true);

      if ($('#Form__input_email').val()?.length === 0) {
        $('#Form__input_email').val(contactFields.email)
      }

      contactFields.customer_from.forEach(function (i) {
        $(`.Form__checkbox_customer_from:checkbox[value='${i}']`).prop('checked', true);
      });

      if ($('#Form__input_customer_from_magazine_name').val()?.length === 0) {
        $('#Form__input_customer_from_magazine_name').val(contactFields.customer_from_magazine_name)
      }

      if ($('#Form__input_customer_from_staff_name').val()?.length === 0) {
        $('#Form__input_customer_from_staff_name').val(contactFields.customer_from_staff_name)
      }

      if ($('#Form__input_customer_from_introducer_name').val()?.length === 0) {
        $('#Form__input_customer_from_introducer_name').val(contactFields.customer_from_introducer_name)
      }

      if ($('#Form__input_customer_from_other').val()?.length === 0) {
        $('#Form__input_customer_from_other').val(contactFields.customer_from_other)
      }

      $(`.Form__radio_contact_way:radio[value='${contactFields.contact_way}']`).prop('checked', true);

      $(`.Form__radio_mailcheck:radio[value='${contactFields.mailcheck}']`).prop('checked', true);

      if (contactFields.accept_privacy) {
        $('#Form__checkbox_privacy').prop('checked', true)
      }
    }
  },

  removeContactFields () {
    $('#submit_bukken_contact').on('click', function () {
      localStorage.removeItem(BUKKEN_KEY)
    })
  },

  handleValidatePhone: function () {
    let isValid = true
    const telRegex = /^\d{8}$|^\d{9}$|^\d{10}$|^\d{11}$/
    const inputTel = $('.phone')
    let inputVal = inputTel.val().replace(/[-－]/g,'')
    if (inputVal > 0 && !telRegex.test(inputVal)) {
      isValid = false
      $(inputTel).addClass('errors')
      $(inputTel).parent().children('.errors-messages').html('<label class="alert">数字8桁〜11桁まで入力してください。</label>')
    } else if (inputVal && telRegex.test(inputVal)) {
      $(inputTel).parent().children('.errors-messages').html(null)
    }
    return isValid
  },

  isDateRequire: function() {
    let active_date_require_value = ['1', '7', '8'];
    let checked_value = [];
    $('input.Form__checkbox_content:checked').each(function () {
      checked_value.push($(this).val());
    });
    return checked_value.some(ai => active_date_require_value.includes(ai))
  }

}
export default Sales
