import Home from './pages/home'
import CatalogContact from "./pages/catalogs/contact";
import Information from "./pages/information";
import CatalogStarterContact from "./pages/catalog_starter_contact";
import Starter from "./pages/starter";
import EventContact from "./pages/event_contact"
import Reservation from "./pages/reservation"
import BrandFavorite from "./pages/mypage/brand_favorite";
import ArticleFavorite from "./pages/mypage/article_favorite";
import Standardized from "./pages/standardized";
import Magazine from "./pages/magazine"
import CompareBrand from './pages/standardized/compare_brand'
import ConstructionCase from "./pages/construction_case";
import SocialPlugin from "./pages/social_plugin";
import ImageFavorite from "./pages/mypage/image_favorite";
import MachinakaContact from "./pages/machinaka_contact";
import FloorPlan from "./pages/floor_plan";
import Sales from "./pages/sales";

$(document).ready(function (e) {
  Home.init()
  CatalogStarterContact.init()
  CatalogContact.init()
  Starter.init()
  Information.init()
  EventContact.init()
  Reservation.init()
  BrandFavorite.init()
  ArticleFavorite.init()
  Standardized.init()
  Magazine.init()
  CompareBrand.init()
  ConstructionCase.init()
  SocialPlugin.init()
  ImageFavorite.init()
  MachinakaContact.init()
  FloorPlan.init()
  Sales.init()
})
