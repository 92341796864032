var ArticleFavorite = {
  init: function() {
    ArticleFavorite.handleDeleteArticleFavorite()
  },
  handleDeleteArticleFavorite: function() {
    $('.deleteArticleFavorite').click(function (e) {
      e.preventDefault()

      let construction_case_id = $(this).attr('data-construction-case-id')
      $.ajax({
        url: '/mypage/destroy_construction_case_favorite',
        type: 'DELETE',
        dataType: 'json',
        data: { construction_case_id: construction_case_id },
        success: function(data) {
          if (data.status === 200) {
            toastr.success('削除に成功しました。')
            $('.favorite-construction-case[data-construction-case-id="' + construction_case_id + '"]').remove();
            $('.total.favorite-construction-case-count span').text($('.favorite-construction-case').length)
          }
        },
        error: function(XMLHttpRequest, textStatus, errorThrown) {
          toastr.error('削除に失敗しました。')
        }
      });
    })
  }
}

export default ArticleFavorite;
