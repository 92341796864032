import InfiniteScroll from 'infinite-scroll'
import {Navigation, Pagination, Swiper} from 'swiper';

var ConstructionCase = {
  init: function () {
    this.initPagination()
    this.handleSearch()
    this.handleSearchImages()
    this.handleSearchByPublicMember()
    this.handleSearchCountClick()
    this.handleSearchImagesCountClick()
    this.clearInputSearchModal()
    this.loadMoreTags()
    this.handleCreateFavorite()
    this.handleDeleteFavorite()
    this.handleCreateImageFavorite()
    this.handleDeleteImageFavorite()
    this.initInfiniteScrollImage()
    this.handleInfiniteScrollImage()
    this.initSwiper()
    this.handleCloseFilters()
  },

  handleCloseFilters: function () {
    $('#searchWrap.customize-bs-modal .searchClose').on('click', function (e) {
      e.preventDefault();

      ConstructionCase.closeFiltersModal();
    });
  },

  initPagination: function () {
    let paginationSelector = $('#construction-case-pagination')

    if (!paginationSelector.length) {
      return
    }

    let params = new window.URLSearchParams(window.location.search);
    let totalPages = $('#total_pages').val();
    let prevFilters = [];
    params.forEach(function(value, key) {
      if (key != 'page') {
        prevFilters.push(`${key}=${value}`);
      }
    });
    let hrefTextPrefix = (prevFilters.length === 0 ? '?page=' : `?${prevFilters.join('&')}&page=`);

    paginationSelector.pagination({
      items: totalPages,
      displayedPages: 3,
      edges: 3,
      hrefTextPrefix: hrefTextPrefix,
      currentPage: params.get('page'),
      prevText: null,
      nextText: null,
    })

    let currentPage = paginationSelector.pagination('getCurrentPage');

    if (currentPage !== 1) {
      let prePageSelector = $('.btn-pre-page')
      prePageSelector.attr('href', `${hrefTextPrefix}${currentPage - 1}`)
      prePageSelector.removeClass('pagination-disabled')
    }

    if (totalPages != currentPage) {
      let nextPageSelector = $('.btn-next-page')
      nextPageSelector.attr('href', `${hrefTextPrefix}${currentPage + 1}`)
      nextPageSelector.removeClass('pagination-disabled')
    }
  },
  handleSearch: function () {
    $('#submitConstructionCaseSearch').click(function () {
      ConstructionCase.search();
    })
  },
  handleSearchImages: function () {
    $('#submitConstructionCaseSearchImage').click(function () {
      ConstructionCase.searchImages()
    })
  },
  handleSearchByPublicMember: function () {
    $('.public-member').click(function () {
      ConstructionCase.search()
    })

    $('.public-member-image').click(function () {
      ConstructionCase.searchImages()
    })
  },
  search: function () {
    const selectedKinds = $('#searchCondKind').find('input[type="checkbox"]:checked').map(function() { return $(this).val() }).get();
    const selectedFeatures = $('#searchCondFeature').find('input[type="checkbox"]:checked').map(function() { return $(this).val() }).get();
    const selectedTags = $('#searchCondTag').find('input[type="checkbox"]:checked').map(function() { return $(this).val() }).get();
    let publicMemberChecked = $('.checkbox-public-member').is(':checked')
    let publicMemberValue
    let searchUrl = '/construction_cases/search?'

    let queryParams = ''
    if (selectedKinds.length) {
      selectedKinds.forEach(function (kind) {
        queryParams = `${queryParams}kinds[]=${kind}&`
      })
    }

    if (selectedFeatures.length) {
      selectedFeatures.forEach(function (feature) {
        queryParams = `${queryParams}features[]=${feature}&`
      })
    }

    if (selectedTags.length) {
      selectedTags.forEach(function (tag) {
        queryParams = `${queryParams}tags[]=${tag}&`
      })
    }

    if (publicMemberChecked) {
      queryParams = `${queryParams}only_public_member=false&`
      publicMemberValue = false
    }

    const nextURL = `${window.location.origin}/construction_cases?${queryParams}`
    const nextTitle = document.title
    const nextState = { search: 'update search result' }
    window.history.replaceState(nextState, nextTitle, nextURL);

    $('.constructionCaseLoading').show()
    $('.constructionCaseContainer').hide()

    $.ajax({
      url: searchUrl,
      method: 'GET',
      data: {
        'kinds[]': selectedKinds,
        'features[]': selectedFeatures,
        'tags[]': selectedTags,
        'only_public_member': publicMemberValue
      },
      success: function(res) {
        $('.constructionCaseLoading').hide()
        $('.constructionCaseContainer').show()
        ConstructionCase.closeFiltersModal();

        setTimeout(function () {
          ConstructionCase.init()
        }, 50)
        window.lazyLoadInstance.update()
      },
      error: function (request, status, error) {
        return toastr.error('エラーが発生しました。')
      }
    })
  },
  searchImages: function () {
    const selectedTags = $('#searchCondTag').find('input[type="checkbox"]:checked').map(function () { return $(this).val() }).get();
    let searchUrl = '/construction_cases/search_images?'
    let queryParams = ''
    let publicMemberChecked = $('.checkbox-public-member').is(':checked')
    let publicMemberValue

    if (selectedTags.length) {
      selectedTags.forEach(function (tag) {
        queryParams = `${queryParams}tags[]=${tag}&`
      })
    }

    if (publicMemberChecked) {
      queryParams = `${queryParams}only_public_member=false&`
      publicMemberValue = false
    }

    const nextURL = `${window.location.origin}/construction_cases/images?${queryParams}`
    const nextTitle = document.title
    const nextState = {search: 'update search result'}
    window.history.replaceState(nextState, nextTitle, nextURL);

    $('.constructionCaseLoadingImage').show()
    $('.construction-case-image-container').hide()

    $.ajax({
      url: searchUrl,
      method: 'GET',
      data: {
        'tags[]': selectedTags,
        'only_public_member': publicMemberValue,
      },
      success: function (res) {
        setTimeout(function () {
          $('.constructionCaseLoadingImage').hide()
          $('.construction-case-image-container').show()
          ConstructionCase.closeFiltersModal();

          ConstructionCase.init()
        }, 50)
        window.lazyLoadInstance.update()
      },
      error: function (request, status, error) {
        return toastr.error('エラーが発生しました。')
      }
    })
  },
  handleSearchCountClick: function () {
    $('.construction-case-search-filter').click(function () {
      ConstructionCase.searchCount()
    })
  },
  handleSearchImagesCountClick: function () {
    $('.construction-case-search-image-filter').click(function () {
      ConstructionCase.searchImagesCount()
    })
  },
  searchImagesCount: function () {
    const selectedTags = $('#searchCondTag').find('input[type="checkbox"]:checked').map(function() { return $(this).val() }).get();
    let searchUrl = '/construction_cases/search_images_count?'

    $.ajax({
      url: searchUrl,
      method: 'GET',
      data: {
        'tags[]': selectedTags,
      },
      error: function (request, status, error) {
        return toastr.error('エラーが発生しました。')
      }
    })
  },
  searchCount: function () {
    const selectedKinds = $('#searchCondKind').find('input[type="checkbox"]:checked').map(function() { return $(this).val() }).get();
    const selectedFeatures = $('#searchCondFeature').find('input[type="checkbox"]:checked').map(function() { return $(this).val() }).get();
    const selectedTags = $('#searchCondTag').find('input[type="checkbox"]:checked').map(function() { return $(this).val() }).get();

    let searchUrl = '/construction_cases/search_count?'

    $.ajax({
      url: searchUrl,
      method: 'GET',
      data: {
        'kinds[]': selectedKinds,
        'features[]': selectedFeatures,
        'tags[]': selectedTags,
      },
      success: function(res) {
      },
      error: function (request, status, error) {
        return toastr.error('エラーが発生しました。')
      }
    })
  },
  loadMoreTags: function () {
    $('.load-more-tags').click(function () {
      $('.Trendkeyword .tag a').show()
      $(this).hide()
      $('#constructionCaseTag').css('max-height','initial');
    })
  },

  clearInputSearchModal: function () {
    $('.clearSearchModalCheck').click(function () {
      $('#constructionCaseSearchForm').find("input[type='checkbox']").prop('checked', false)
      const modalName = $(this).data('modal-name')
      switch (modalName) {
        case 'construction_case':
          ConstructionCase.searchCount()
          break
        case 'construction_case_image':
          ConstructionCase.searchImagesCount()
      }
    })
  },
  handleCreateFavorite: function() {
    $('.createConstructionCaseFavorite').click(function (e) {
      e.preventDefault()
      const construction_case_id = $(this).data('construction-case-id')

      $.ajax({
        url: '/construction_cases/favorites',
        type: 'POST',
        dataType: 'json',
        data: { construction_case_id: construction_case_id },
        success: (data) => {
          if (data && data.status === 200) {
            toastr.success('お気に入りに追加しました。')
            $('.deleteConstructionCaseFavoriteForm[data-construction-case-id="' + construction_case_id + '"]').show();
            $('.createConstructionCaseFavoriteForm[data-construction-case-id="' + construction_case_id + '"]').hide();
          } else {
            toastr.error('登録に失敗しました。')
          }
        },
        error: function(XMLHttpRequest, textStatus, errorThrown) {
          toastr.error('登録に失敗しました。')
        }
      });
    })
  },
  handleDeleteFavorite: function() {
    $('.deleteConstructionCaseFavorite').click(function (e) {
      e.preventDefault()
      const construction_case_id = $(this).attr('data-construction-case-id')

      $.ajax({
        url: `/construction_cases/favorites/${construction_case_id}`,
        type: 'DELETE',
        dataType: 'json',
        success: (data) => {
          if (data && data.status === 200) {
            toastr.success('お気に入りから削除しました。')
            $('.deleteConstructionCaseFavoriteForm[data-construction-case-id="' + construction_case_id + '"]').hide();
            $('.createConstructionCaseFavoriteForm[data-construction-case-id="' + construction_case_id + '"]').show();
          } else {
            toastr.error('削除に失敗しました。')
          }
        },
        error: function(XMLHttpRequest, textStatus, errorThrown) {
          toastr.error('削除に失敗しました。')
        }
      });
    })
  },
  handleCreateImageFavorite: function() {
    $('.createConstructionCaseImageFavorite').click(function (e) {
      e.preventDefault()
      const construction_case_image_id = $(this).data('construction-case-image-id')

      $.ajax({
        url: '/construction_cases/image_favorites',
        type: 'POST',
        dataType: 'json',
        data: { construction_case_image_id: construction_case_image_id },
        success: (data) => {
          if (data && data.status === 200) {
            toastr.success('お気に入りに追加しました。')
            $('.deleteConstructionCaseImageFavoriteForm[data-construction-case-image-id="' + construction_case_image_id + '"]').show();
            $('.createConstructionCaseImageFavoriteForm[data-construction-case-image-id="' + construction_case_image_id + '"]').hide();
          } else {
            toastr.error('登録に失敗しました。')
          }
        },
        error: function(XMLHttpRequest, textStatus, errorThrown) {
          toastr.error('登録に失敗しました。')
        }
      });
    })
  },
  handleDeleteImageFavorite: function() {
    $('.deleteConstructionCaseImageFavorite').click(function (e) {
      e.preventDefault()
      const construction_case_image_id = $(this).attr('data-construction-case-image-id')

      $.ajax({
        url: `/construction_cases/image_favorites/${construction_case_image_id}`,
        type: 'DELETE',
        dataType: 'json',
        success: (data) => {
          if (data && data.status === 200) {
            toastr.success('お気に入りから削除しました。')
            $('.deleteConstructionCaseImageFavoriteForm[data-construction-case-image-id="' + construction_case_image_id + '"]').hide();
            $('.createConstructionCaseImageFavoriteForm[data-construction-case-image-id="' + construction_case_image_id + '"]').show();
          } else {
            toastr.error('削除に失敗しました。')
          }
        },
        error: function(XMLHttpRequest, textStatus, errorThrown) {
          toastr.error('削除に失敗しました。')
        }
      });
    })
  },
  initInfiniteScrollImage: function () {
    if ($('.construction-case-image-container').length === 0) {
      return
    }

    new InfiniteScroll( '.construction-case-image-container', {
      path: '.pagination__next',
      checkLastPage: '.pagination__next',
      append: '.image-item',
      prefill: false,
      responseBody: 'text',
      domParseResponse: true,
      fetchOptions: undefined,
      outlayer: false,
      scrollThreshold: 400,
      elementScroll: false,
      loadOnScroll: true,
      history: false,
      historyTitle: true,
      hideNav: undefined,
      status: undefined,
      button: undefined,
      debug: false,
    })

    // MEMO: Swiper のデータも生成する
    new InfiniteScroll( '.swiper-wrapper', {
      path: '.pagination__next',
      checkLastPage: '.pagination__next',
      append: '.swiper-slide',
      prefill: false,
      responseBody: 'text',
      domParseResponse: true,
      fetchOptions: undefined,
      outlayer: false,
      scrollThreshold: 400,
      elementScroll: false,
      loadOnScroll: true,
      history: false,
      historyTitle: true,
      hideNav: undefined,
      status: undefined,
      button: undefined,
      debug: false,
    })
  },

  handleInfiniteScrollImage: function() {
    const PAGINATION_ITEM_COUNT = 50
    let container = $('.construction-case-image-container')
    container.on('append.infiniteScroll', function(event, body, path, items, response) {
      let pageNum = path.split('page=')[1]
      for (let i = 0; i < items.length; i++) {
        let item = items[i];
        // MEMO: 自動生成されたモーダルに対応するIndexを計算する処理。
        // ページネーションで生成される枚数(50枚) * ページ番号（2ページ目から読み込まれるため -1) + 処理するNodeListのインデックス（モーダルのindexは1開始のため + 1 する)
        let modalIndex = (PAGINATION_ITEM_COUNT * (pageNum - 1)) + (i + 1)
        item.querySelector('.swiperOpen').dataset.modal = modalIndex
      }
      ConstructionCase.initSwiper();
      window.lazyLoadInstance.update();
    });

  },

  initSwiper: function () {
    Swiper.use([Pagination, Navigation])

    // モーダルを取得
    const modal = document.getElementById("modalSwiper");
    // モーダルを開く
    const openModalBtns = document.querySelectorAll(".swiperOpen");
    // モーダルを閉じる
    const closeModalBtns = document.querySelectorAll(".swiperClose");

    // Swiperの設定
    const swiper = new Swiper(".swiper", {
      loop: true,
      //speed: 3000,
      navigation: {
        nextEl: ".swiper-next",
        prevEl: ".swiper-prev",
      },
      //spaceBetween: 30, //任意のマージン
    });

    // モーダルのボタンクリック
    openModalBtns.forEach((openModalBtn) => {
      openModalBtn.addEventListener("click", () => {
        // data-modalで設定したスライド番号を取得
        const modalIndex = openModalBtn.getAttribute('data-modal');
        swiper.slideTo(modalIndex);
        modal.classList.add("is-active");
      });
    });

    // モーダルの閉じるボタンクリック
    closeModalBtns.forEach((closeModalBtn) => {
      closeModalBtn.addEventListener("click", () => {
        modal.classList.remove("is-active");
      });
    });
  },

  closeFiltersModal: function () {
    $('#searchWrap').modal('hide');
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
    $('body').removeAttr('style');
  },
}

export default ConstructionCase
